globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"7148e6865d225d49aeee51b1b13399ba2f7434cc"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {
  standardSentryConfig,
  extraClientConfig,
} from '@mentimeter/sentry-config';

function enablePerformance() {
  const environmentName = globalThis.__mentimeterEnv['NEXT_PUBLIC_ENVIRONMENT_NAME'] ?? 'dev';
  // Enable performance metrics for voting in CF and prod.
  if (environmentName === 'prod') {
    return 0.15;
  }
  return 0;
}

Sentry.init({
  dsn:
    globalThis.__mentimeterEnv['NEXT_PUBLIC_PAGES_ROUTER'] === 'true'
      ? 'https://acefa0c4155042c7a28a0fae734bb32d@o866780.ingest.sentry.io/5830343'
      : 'https://490613dcab92107eb636ffff0121af01@o866780.ingest.sentry.io/4506398899306496',
  ...standardSentryConfig,
  ...extraClientConfig,
  // This MUST be here as overwrite in the client config for env to be set with next-env-vars
  // Can be removed when this application is cf pages only
  environment: globalThis.__mentimeterEnv['NEXT_PUBLIC_ENVIRONMENT_NAME'] ?? 'dev',
  tracesSampleRate: enablePerformance(),
});
